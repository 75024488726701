/* eslint-disable max-len */
const noop = function() {};
const konsole = window.console && console.log ? console : {
  log: noop, dir: noop, error: noop
};
const isObject = function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
};

const mergeDeep = function mergeDeep(target /*, many, of, args */) {
  const sources = Array.prototype.slice.call(arguments, 1);
  for (let s = 0; s < sources.length; s++) {
    const source = sources[s];
    if (isObject(target) && isObject(source)) {
      const keys = Object.keys(source);
      let i = 0;
      let key;
      for (; i < keys.length; i++) {
        key = keys[i];
        if (isObject(source[key])) {
          if (!target[key]) { target[key] = {}; }
          mergeDeep(target[key], source[key]);
        }
        else {
          target[key] = source[key];
        }
      }
    }
  }
  return target;
};


const el_liveBind = function el_liveBind(element, event, descendentSelector, callback, evtOpts) {
  const eventNames = event.trim().split(/\s+/);
  evtOpts = typeof evtOpts !== "undefined" ? evtOpts : { passive: false };

  function listener(e) {
    const elem = descendentSelector ? e.target.closest(descendentSelector) : element;
    if (elem && typeof callback === 'function') {
      callback.call(elem, e, elem, unbind);
    }
  }

  function unbind(e) {
    for (let i = 0; i < eventNames.length; i++) {
      element.removeEventListener(eventNames[i], listener);
    }
  }

  for (let i = 0; i < eventNames.length; i++) {
    element.addEventListener(eventNames[i], listener, evtOpts);
  }

  return unbind;
};


const el_trigger = function el_trigger(el, event, data, bubbles = true, cancelable = false) {
  const eventNames = event.trim().split(/\s+/);
  if (!el || !el.dispatchEvent) {
    konsole.error('el_trigger() Invalid arg0 "el":', el, event);
    return;
  }
  for (let i = 0; i < eventNames.length; i++) {
    el.dispatchEvent(
      new window.CustomEvent(eventNames[i], {
        bubbles: !!bubbles,
        cancelable: !!cancelable,
        detail: { customEvent: true, customData: data || null }
      })
    );
  }
};


const el_getHeight = function el_getHeight(el) {
  const styles = window.getComputedStyle(el);
  const height = el.offsetHeight;
  const borderTopW = parseFloat(styles.borderTopWidth);
  const borderBottomW = parseFloat(styles.borderBottomWidth);
  const paddingTop = parseFloat(styles.paddingTop);
  const paddingBottom = parseFloat(styles.paddingBottom);
  return height - borderBottomW - borderTopW - paddingTop - paddingBottom;
};


/**
 * Getter to return sibling elements of the passed one
 * @param elem: HTMLElement
 * @return {Array} Array of sibling elements.
 */
const getSiblingsElements = function(elem) {
  const siblings = [];

  if (elem && elem.parentNode) {
    let sibling = elem.parentNode.firstChild;

    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) { siblings.push(sibling); }
      sibling = sibling.nextSibling;
    }
  }

  return siblings;
};


/**
 * A stateless keyboard utility to -
 * - Trap focus,
 * - Focus the correct Element
 * @param el_wrapper: HTMLElement. The Parent element, within which the focus should be trapped
 * @param el_for_initial_focus: <Optional> HTMLElement. If Not provided, focus is put to the first focusable element
 * @return {Function} Function. The cleanup function. To undo everything done for handling A11Y
 */

const trapFocus = (function() {
  const FOCUSABLES = (
    '[role="button"], a[href], area[href], input, select, textarea, button, iframe, object, [tabindex="0"], [contenteditable]'
  ).split(/,\s+?/).join(':not([disabled]), ');

  const isVisible = el => !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);

  return function(el_wrapper, el_for_initial_focus) {
    if (!el_wrapper) {
      throw new Error('Could not initialize focus-trapping - Element Missing');
    }

    const focusableElements = Array.prototype.slice.call(
      el_wrapper.querySelectorAll(FOCUSABLES), 0
    ).filter(isVisible);

    let keyboardHandler;

    if (focusableElements.length > 0) {
      const firstFocusableEl = focusableElements[0];
      const lastFocusableEl = focusableElements[focusableElements.length - 1];
      const el_autofocus = el_wrapper.querySelector('[autofocus]');
      const elementToFocus = el_for_initial_focus || (
        el_autofocus || firstFocusableEl
      );
      elementToFocus.focus();

      keyboardHandler = function(e) {
        if (e.keyCode === 9) { // Tab key
          if (e.shiftKey && document.activeElement === firstFocusableEl) {
            e.preventDefault();
            lastFocusableEl.focus();
          }
          else if (!e.shiftKey && document.activeElement === lastFocusableEl) {
            e.preventDefault();
            firstFocusableEl.focus();
          }
        }
      };
      el_wrapper.addEventListener('keydown', keyboardHandler);
    }

    return function cleanUp() {
      if (keyboardHandler) {
        el_wrapper.removeEventListener('keydown', keyboardHandler);
      }
    };
  };
})();


const keyCodes = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  ESC: 27,
  SPACE: 32,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40
};


const util = {
  noop, konsole, isObject, mergeDeep, el_liveBind, el_trigger, el_getHeight, getSiblingsElements, trapFocus, keyCodes
};


export {
  noop, konsole, isObject, mergeDeep, el_liveBind, el_trigger, el_getHeight, getSiblingsElements, trapFocus, keyCodes
};

export default util;
