import './css/base.css';
import './css/slider.css';
import './css/switch.css';
import './css/theme.css';
import './js/_shims';
import DwCookieConsent from './js/dw-cookieconsent';

window.DwCookieConsent = DwCookieConsent;

export default DwCookieConsent;
