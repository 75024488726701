import { konsole, el_liveBind, el_trigger, mergeDeep, trapFocus } from './util';
import TemplateParser from './tplparser';
import EventEmitter from './EventEmitter';

export default class Component {
  constructor(cid, data, dwCookieConsent) {
    const compTypeSets = dwCookieConsent.settings.componentTypes || {};
    this._emitter = new EventEmitter('DwCookieConsent', this);
    this.componentType = compTypeSets[data.type] || {};
    this.cid = cid;
    this.type = data.type;
    this.instanceId = 'dwcc_ciid_' + this._incrId.get();
    this.dwCookieConsent = dwCookieConsent;
    this.componentType.dwCookieConsent = dwCookieConsent;
    this._elHolder = null;
    this._unbinds = [];
    this.state = {};
    this.causes = {
      hide: null,
      show: null
    };

    this.componentData = Object.assign(
      {
        type: data.type,
        cid,
        state: this.state,
        visible: true,
        inline: false,
        dwCookieConsent
      },
      this.componentType.defaults || {}, data
    );

    // eslint-disable-next-line guard-for-in
    for (const prop in this.componentType) {
      this[prop] = this.componentType[prop];
    }

    if (typeof this.componentData.init === 'function') {
      this.componentData.init(this, dwCookieConsent);
    }

    this._errorPre = (
      `This componentType ${data.type} of ${cid || 'N/A'} component `
    );

    if (!this.componentData.type || !this.componentType) {
      throw new Error(this._errorPre + 'does not exist.');
    }

    if (typeof this.componentData.visible === "function") {
      this.visible = !!this.componentData.visible(this);
    }
    else {
      this.visible = !!this.componentData.visible;
    }

    this.elComponent = document.createElement(
      this.componentData.inline ? 'span' : 'div'
    );

    this.elComponent.className = [
      this.componentData.extraClass || '',
      "dwcc-component",
      "dwcc-type-" + this.componentData.type,
      "dwcc-component-" + this.cid
    ].join(' ').trim();


    this.templateData = Object.assign({
      component: this.componentData
    }, this.dwCookieConsent.templateData);

    this.events = mergeDeep(
      {},
      this.componentType.events || {},
      this.componentData.events || {}
    );

    this.renderer = this.parser.createRenderer({
      template: this.componentType.template,
      data: this.templateData,
      id: 'Component.renderer',
      context: this
    });


    if (typeof this.componentType.extend === "function") {
      this.componentType.extend.call(this, this.componentData);
    }
    if (this.visible === false) { this.hide(); }
    this.setAttr('id', "dwcc-instance-id-" + this.instanceId);
    this.setAttr('data-instance-id', this.instanceId);

    const attributes = Object.assign(
      {},
      (typeof this.componentType.attributes === "object" && this.componentType.attributes) || {},
      (typeof this.componentData.attributes === "object" && this.componentData.attributes) || {}
    );

    for (const prop in attributes) {
      if (attributes.hasOwnProperty(prop) && prop) {
        const val = typeof attributes[prop] === "string" ? attributes[prop] : (
          typeof attributes[prop] === "function" ? attributes[prop](this) : ''
        );
        if (val !== null) { this.setAttr(prop, this.renderer(val + '')); }
      }
    }

    this.emit('onConstructed', this);
  }

  bindEvents(events, _selector) {
    if (typeof events !== "object") { return; }

    _selector = typeof _selector === "string" && _selector ? _selector : null;

    for (const prop in events) {
      if (!events.hasOwnProperty(prop)) { continue; }

      if (typeof events[prop] === 'function') {
        let element = this.elComponent;

        if (_selector === '{document}') { element = document; }
        if (_selector === '{window}') { element = window; }
        if (_selector.charAt(0) === '{') { _selector = false; }
        this._unbinds.push(
          el_liveBind(element, prop, _selector, (function(fn, that) {
            return function(e) {
              const args = Array.prototype.slice.call(arguments, 1);
              args.unshift(e, this, that.componentData);
              fn.apply(that, args);
            };
          })(events[prop], this))
        );
      }
      else if (typeof events[prop] === 'object' && events[prop]) {
        this.bindEvents(events[prop], prop);
      }
    }
  }

  unbindEvents() {
    while (this._unbinds.length) {
      this._unbinds.pop()();
    }
  }

  getComponentHolder() {
    return (
      '<span' +
        ' aria-hidden="true" ' +
        ' data-cid="' + this.cid + '"' +
        ' data-instance-id="' + this.instanceId + '"' +
        ' id="' + this.instanceId + '-holder"' +
        ' class="' + this.dwCookieConsent.classNameHolder + '"' +
      '></span>'
    );
  }

  render(_obligate) {
    // eslint-disable-next-line no-cond-assign
    if ((!_obligate && this.rendered) || !(this.rendered = true)) { return; }
    this.elComponent.innerHTML = this.renderer();

    el_trigger(this.elComponent, 'dwcc-render');

    this.dwCookieConsent.attachComponenets(this.elComponent);

    el_trigger(this.elComponent, 'dwcc-rendered');
    this.emit('onRender', this);
  }

  clear() {
    this.unbindEvents();
    const nested = [];
    let nodes = Array.prototype.slice.call(
      this.elComponent.getElementsByClassName('dwcc-component')
    );
    nodes.forEach((node) => {
      const elems = node.getElementsByClassName('dwcc-component');
      if (elems.length) { nested.push(...elems); }
    });
    nodes = nodes.filter(node => nested.indexOf(node) === -1);

    for (let i = 0; i < nodes.length; i++) {
      const instanceId = nodes[i].getAttribute('data-instance-id');
      const childComponent = this.dwCookieConsent.getComponent(instanceId, 'instanceId');
      if (childComponent) {
        childComponent.detach();
      }
    }

    this.rendered = false;
    this.elComponent.innerHTML = '';
  }

  renderContentTo(el) {
    this.render();
    el.innerHTML = this.elComponent.innerHTML;
  }

  detach() {
    if (!this.attached) { return; }

    this.clear();
    this.elComponent.parentNode.insertBefore(this._elHolder, this.elComponent);
    this._elHolder.parentNode.removeChild(this.elComponent);
    this.attached = false;
    el_trigger(this.elComponent, 'dwcc-detached');
    this.emit('onDettached', this);
  }

  attach(_elHolder, _force) {
    if (this.attached && _force !== true) { return; }

    if (typeof _elHolder === 'undefined') {
      _elHolder = document.getElementById(this.instanceId + '-holder');
    }
    else if (!_elHolder || _elHolder.nodeType !== 1) {
      konsole.log('Err: arg1 is not an element node', _elHolder);
      return;
    }

    if (_elHolder && _elHolder.nodeType === 1) {
      this._elHolder = _elHolder;

      if (!this.visible && _force !== true) {
        // rather attach it the next time the element appears
        return;
      }

      this.attached = true;

      if (_elHolder.parentNode) {
        _elHolder.parentNode.insertBefore(this.elComponent, _elHolder);
        _elHolder.parentNode.removeChild(_elHolder);
      }
      else {
        konsole.log('Err: arg0.parentNode does not exist.', _elHolder);
      }

      this.bindEvents(this.events);
      this.render();

      el_trigger(this.elComponent, 'dwcc-attached');

      this.emit('onAttached', this);
    }
  }

  callAction(actionId) {
    actionId = actionId || this.componentData.action;

    if (!actionId) {
      konsole.log(this.cid + ': invalid action id [' + actionId + ']');
      return;
    }

    if (typeof actionId === "function") {
      actionId.call(this.componentData, this);
    }
    else if (typeof this.dwCookieConsent.actions[actionId] === 'function') {
      this.dwCookieConsent.actions[actionId].call(this.componentData, this);
    }
    else {
      konsole.log(`settings.action. ${actionId}() does not exist.`);
    }
    this.emit('onCallAction', this, actionId);
  }

  // eslint-disable-next-line class-methods-use-this
  resize() {

  }

  setAttr(prop, val) {
    this.elComponent.setAttribute(prop, val);
    return this;
  }

  removeAttr(prop) {
    this.elComponent.removeAttribute(prop);
    return this;
  }

  show(cause) {
    this.visible = true;
    if (!this.attached && this._elHolder) {
      this.attach(this._elHolder);
    }
    this.causes.show = cause || null;
    this.elComponent.classList.toggle('hidden', false);
    this.removeAttr('aria-hidden').removeAttr('hidden');
    this.dwCookieConsent.resize();

    let elAutoFocus = null;
    if (this.componentData.trapFocus) {
      this._clearTrapFocus = trapFocus(this.elComponent);
      this.emit('onFocus', this);
    }
    // eslint-disable-next-line no-cond-assign
    else if ((elAutoFocus = this.elComponent.querySelector('[autofocus]'))) {
      elAutoFocus.focus();
      this.emit('onFocus', this);
    }

    this.emit('onShow', this, cause);
  }

  hide(cause) {
    this.causes.hide = cause || null;
    this.elComponent.classList.toggle('hidden', true);
    this.setAttr('aria-hidden', 'true').setAttr('hidden', '');
    this.visible = false;

    if (typeof this._clearTrapFocus === "function") {
      this._clearTrapFocus();
      this._clearTrapFocus = null;
    }
    this.dwCookieConsent.resize();
    if (this.detachable) {
      this.detach();
    }

    this.emit('onHide', this, cause);
  }

  on(eventName, listener, data) {
    this._emitter.on(eventName, listener, data, this);
  }

  once(eventName, listener, data) {
    this._emitter.on(eventName, listener, data, this, true);
  }

  off(eventName, listener) {
    this._emitter.off(eventName, listener);
  }

  emit(eventName, ...args) {
    if (typeof this[eventName] === 'function') {
      this[eventName](...args);
    }
    if (typeof this.componentData[eventName] === 'function') {
      this.componentData[eventName].apply(this, args);
    }
    this._emitter.emit(eventName, ...args);
  }

  destroy() {
    this.clear();
    // TODO: implement this
  }
}

Component.prototype.parser = new TemplateParser();
Component.prototype._incrId = { i: 0, get() { return this.i++; } };
